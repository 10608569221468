import React from 'react'
import Translate from '../../utils/translate'

export default (props) => {
  return (
    <div>
      <h3 className='text-2xl font-bold text-center text-gray-700'>{Translate(props.codeLanguage, 'introduce_h1')}</h3>
      <h3 className='text-2xl font-bold text-center text-gray-700'>{Translate(props.codeLanguage, 'introduce_h2')}</h3>
      <div className='text-base font-light text-center text-gray-700 mt-5 hidden md:block'>
        <p>{Translate(props.codeLanguage, 'introduce_p1')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p2')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p3')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p4')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p5')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p6')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p7')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_p8')}</p>
      </div>
      <div className='text-base font-light text-center text-gray-700 ml-3 mt-5 block md:hidden'>
        <p>{Translate(props.codeLanguage, 'introduce_pm1')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm2')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm3')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm4')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm5')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm6')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm7')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm8')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm9')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm10')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm11')}</p>
        <p>{Translate(props.codeLanguage, 'introduce_pm12')}</p>
      </div>
    </div>
  )
}
