const programmingLanguages = () => [
    {
        name: 'SQL',
        value: 0.9,
    },
    {
        name: 'PL/SQL',
        value: 0.9,
    },
    {
        name: 'Python',
        value: 0.8,
    },
    {
        name: 'JavaScript',
        value: 0.8,
    },
    {
        name: 'Dart',
        value: 0.6,
    },
    {
        name: 'Flutter',
        value: 0.7,
    },
    {
        name: 'HTML/CSS',
        value: 0.8,
    },
];

const webFrameworks = () => [
    {
        name: 'ReactJS',
        value: 0.85,
    },
    {
        name: 'Gatsby',
        value: 0.80,
    },
    {
        name: 'Jest',
        value: 0.6,
    },
    {
        name: 'Tailwind',
        value: 0.8,
    },
    {
        name: 'NodeJS',
        value: 0.75,
    },
];

const databases = () => [
    {
        name: 'Oracle',
        value: 0.9,
    },
    {
        name: 'MySQL',
        value: 0.8,
    },
    {
        name: 'PostgreSQL',
        value: 0.8,
    },
    {
        name: 'SQLite',
        value: 0.65,
    },
    {
        name: 'MongoDB',
        value: 0.7,
    },
];

const platforms = () => [
    {
        name: 'Linux',
        value: 0.75,
    },
    {
        name: 'Windows',
        value: 0.9,
    },
    {
        name: 'macOS',
        value: 0.9,
    },
    {
        name: 'Docker',
        value: 0.8,
    },
    {
        name: 'GCP',
        value: 0.6,
    },
    {
        name: 'Firebase',
        value: 0.6,
    },
];

const devTools = () => [
    {
        name: 'VSCode',
        value: 0.90,
    },
    {
        name: 'Git',
        value: 0.9,
    },
    {
        name: 'GitHub',
        value: 0.85,
    },
    {
        name: 'GitLab',
        value: 0.85,
    },
];

export default {
    programmingLanguages,
    webFrameworks,
    databases,
    platforms,
    devTools
};