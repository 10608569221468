import React from 'react'
import CardAbout from './cardAbout'
import TextAbout from './textAbout'
import Translate from '../../utils/translate'

export default (props) => {
  return (
    <div className='max-w-4xl mx-auto mt-20'>
      {/* <p className='hidden xs:block'>
        XS
      </p>
      <p className='hidden sm:block'>
        SM
      </p>
      <p className='hidden md:block'>
        MD
      </p>
      <p className='hidden lg:block'>
        LG
      </p>
      <p className='hidden xl:block'>
        XL
      </p> */}
      <h2 id='about' className='text-4xl font-bold text-center text-gray-800'>{Translate(props.codeLanguage, 'about')}</h2>
      <div className='flex justify-center md:mt-20'>
        <div className='rounded overflow-hidden shadow-lg hidden md:block' style={{height: '420px', width: '285px'}}
             data-sal='slide-right'
             data-sal-delay='300'
             data-sal-easing='easeInSine'>
          <CardAbout />
        </div>
        <div className='ml-0 mt-12 md:ml-6 lg:ml-20'
             data-sal='slide-left'
             data-sal-delay='300'
             data-sal-easing='easeInSine'>
          <TextAbout codeLanguage={props.codeLanguage} />
        </div>
      </div>
      <div className='flex justify-center mt-10 md:hidden'>
        <div className='rounded overflow-hidden shadow-lg' style={{height: '353px', width: '235px'}}
             data-sal='slide-right'
             data-sal-delay='300'
             data-sal-easing='easeInSine'>
          <CardAbout />
        </div>
      </div>
    </div>
  )
}
